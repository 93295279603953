import { template as template_44bfce99175e4453988f710da348f255 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_44bfce99175e4453988f710da348f255(`
  <div class="instructions-content" tabindex="0">
    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.4.text" htmlSafe=true}}
    </p>
    <ol class="instructions-content__list instructions-content__list--decimal">
      <li>{{t "pages.certification-instructions.steps.4.list.1"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.2"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.3"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.4"}}</li>
    </ol>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
