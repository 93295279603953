import { template as template_d6dda8ce618a474f82e65384357b59a7 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { t } from 'ember-intl';
export default template_d6dda8ce618a474f82e65384357b59a7(`
  <PixBannerAlert @type="info">
    {{t "pages.modulix.beta-banner"}}
  </PixBannerAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
