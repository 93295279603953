import { template as template_df3945a648c347cdadb35941a08a3617 } from "@ember/template-compiler";
export default template_df3945a648c347cdadb35941a08a3617(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
