import { template as template_c6087d9c1d7f4f32ab35b153953f498a } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_c6087d9c1d7f4f32ab35b153953f498a(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
