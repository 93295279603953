import { template as template_75e8b0c4308d4eaf9003898e84247379 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_75e8b0c4308d4eaf9003898e84247379(`
  <div class="instructions-content" tabindex="0">
    <h3 class="instructions-content__title">
      {{t "pages.certification-instructions.steps.1.question"}}
    </h3>

    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.1.paragraphs.1" htmlSafe=true}}
    </p>

    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.1.paragraphs.2" htmlSafe=true}}
    </p>

    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.1.paragraphs.3" htmlSafe=true}}
    </p>

    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.1.paragraphs.4" htmlSafe=true}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
