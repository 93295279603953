import { template as template_b0aa8f0513984144baee68fab9071245 } from "@ember/template-compiler";
import Footer from './footer';
import Header from './header';
export default template_b0aa8f0513984144baee68fab9071245(`
  <section class="authentication-layout">
    <div class="authentication-layout__side" role="presentation">
      <div class="authentication-layout__image">
        <img alt="" src="/images/illustrations/authentication.svg" />
      </div>
    </div>

    <div class="authentication-layout__main">
      <Header>
        {{yield to="header"}}
      </Header>

      <main ...attributes>
        {{yield to="content"}}
      </main>

      <Footer />
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
